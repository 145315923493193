* {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  font-family: "Nunito", sans-serif;
}
:root {
  --orange: #ff9800;
  --black: #242d49;
  --white: #ffffff;
  --glass: rgba(255, 255, 255, 0.54);
  --lightglass: rgb(255 255 255 / 28%);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --sidebar-menubg: linear-gradient(
    106.37deg,
    #fca61f 29.63%,
    #ff8c00 51.55%,
    #e07b39 90.85%
  );
  --sidebar_bg: rgb(33 28 25);
  --cream: #fffdd0;
  --green: rgb(106, 235, 106);
  --overall-bg: linear-gradient(to right, #ffffff, #e9ecef);
  --textbox-input: rgb(231 231 231);
  --textbox-outline: rgb(221 221 221);
  --placeholder: #525658;
}

.sidebar_layout {
  background-color: var(--sidebar_bg);
  width: 330px;
  height: 100vh;
  overflow-y: auto;
  transition: left 0.1s ease, width 0.5s ease; /* Smooth transition for width and position */
}
.sidebar_collpse_layout {
  background-color: var(--sidebar_bg);
  width: 80px;
  height: 100vh;
  overflow-y: auto;
  transition: left 0.1s ease, width 0.5s ease; /* Smooth transition for width and position */
}
.main_container {
  background: linear-gradient(to right, #ffffff, #e9ecef);
  width: 100%;
  overflow: hidden;
}

.login-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Full viewport height */
}
.cursor_pointer {
  cursor: pointer;
}
.pos_relative {
  position: relative;
}
.login_eye_icon {
  position: absolute;
  right: 10px;
}
.mtop_2rem {
  margin-top: 2rem;
}
.login_btn {
  background-color: #ff9800 !important;
  color: #fff !important;
  border: none !important;
  padding: 10px;
  letter-spacing: 4px;
  text-transform: uppercase;
}
.layout_container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  overflow: hidden;
  height: 100vh;
}
.body_container {
  padding: 10px;
  padding-left: 18px;
  padding-right: 18px;
  height: 80vh;
  overflow: auto;
}

.sidebar_image_container {
  align-items: center !important;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0.5rem;
  height: 13vh;
}
.menuItemContainer {
  padding: 5px;
}
.header_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.navbar_departments {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.navbar_departments div {
  padding: 0.3rem;
  margin: 1px;
}
.navbar_departmentsMobile {
  height: 10vh;
  overflow-y: auto;
  width: 30%;
  text-align: center;
}
.navbar_departmentsMobile div {
  padding: 2px;
  margin-bottom: 2px;
  font-size: 13px;
}
.dboard_ticket_count_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.display_space_between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}
.table_heading {
  color: #e07b39;
  font-weight: bold;
}
.table_css {
  margin-top: 0.5rem;
}
.table_css th {
  font-weight: 600 !important;
}
.sbmenu_item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.menu_item {
  padding: 0.7rem !important;
}

/* Modal overlay (full screen) */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex; /* Use flexbox to center the modal */
  justify-content: center; /* Horizontally center the modal */
  align-items: center; /* Vertically center the modal */
  z-index: 9999; /* Ensure it's above other content */
}

/* Modal content */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: 80%; /* Optional: Set a maximum width for the modal */
  max-width: 1000px; /* Optional: Limit the modal size */
}

/* Close button style */
button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

/* ///notif */

.notification_card .card {
  width: 100%;
  margin-bottom: 5px;
  display: block;
  transition: opacity 0.3s;
  border: none;
}

.notification_card .card-body {
  padding: 0.5rem;

  background: white;
  border-radius: 9px;
}

.notification_card .card-body table {
  width: 100%;
}
.notification_card .card-body table tr {
  display: flex;
}
.notification_card .card-body table tr td a.btn {
  font-size: 0.8rem;
  padding: 3px;
}
.notification_card .card-body table tr td:nth-child(2) {
  text-align: right;
  justify-content: space-around;
}
.notification_card .card-title:before {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 1.1rem;
  text-align: center;
  border: 2px solid grey;
  border-radius: 90px;
  width: 24px;
  height: 24px;
  padding-bottom: 3px;
  margin-right: 10px;
}

.notification_card .notification-invitation .card-body .card-title:before {
  color: #90caf9;
  border-color: #90caf9;
  content: "";
  font-size: 15px;
}

.notification_card .notification-warning .card-body .card-title:before {
  color: #ffe082;
  border-color: #ffe082;
  content: "";
}

.notification_card .notification-danger .card-body .card-title:before {
  color: #ffab91;
  border-color: #ffab91;
  content: "";
}
.notification_card .notification-reminder .card-body .card-title:before {
  color: #ce93d8;
  border-color: #ce93d8;
  content: "";
}
.notification_card .card.display-none {
  display: none;
  transition: opacity 2s;
}


.pagination_styles {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
td {
  font-size: 14.5px;
}
th {
  font-size: 14.5px;
  font-weight: bold;
}
.clip_brdcrumps , button , input , textarea ,select {
  font-size: 14.5px !important;
}
.mysystematic_data {
background: antiquewhite;
margin-bottom: 10px; 
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}