/* Tree.css */

.process-name-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(16, 13, 13);
  padding: 10px;
  border-radius: 8px;
  font-weight: bold;
}

.display_space_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.table_heading {
  font-size: 24px;
  font-weight: bold;
}

select {
  background-color: orange;
  color: black;
  border-color: orange;
  height: 45px;
  width: 100%;
  font-size: 16px;
}

.org-content-container {
  /* background-color: #d2d2d296; */
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-top: 10px;
}
.process-card {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  flex-direction: column;
  height: 200px;
  max-height: 200px;
  overflow: hidden;
  transition: max-height 0.3s ease;
  display: flex;
  flex-direction: column;
}
.process-card.expanded {
  max-height: 500px;
}
.tasks-container.collapsed {
  max-height: 100px;
  overflow: hidden;
}

.tasks-container.expanded {
  max-height: none;
}
.toggle-expand {
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}
.process-card .process-flow-order-circle {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #ffa500;
  border-radius: 50%;
  text-align: center;
  color: white;
  line-height: 30px;
  font-weight: bold;
}

/* Task container styled as a card */
.task-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  transition: box-shadow 0.3s ease;
  overflow-y: auto;
  max-height: 120px;
}
.toggle-expand {
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  color: #007bff;
}

.task-container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.task-left {
  flex: 1;
}

.task-name {
  font-size: 16px;
  color: #333;
  margin: 0;
}

.task-right {
  display: flex;
  justify-content: flex-end;
}

.task-flow-order {
  background-color: #ffa500;
  color: white;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 50%;
  text-align: center;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
}
.task-flow-order1 {
  background-color: #ffa500;
  color: white;
  padding: 7px;
  border-radius: 50%;
  text-align: center;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.draggable {
  cursor: grab;
}

.dragging {
  cursor: grabbing;
}

.process-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s, box-shadow 0.3s;
}
.process-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}
.card-body-animated {
  transition: all 0.3s;
}
.card-body-animated:hover {
  transform: translateY(-2px);
}
