.menuItem-content {
  border-radius: 10px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.menuItem-icon {
  margin-right: 15px;
  font-size: 24px;
  min-width: 30px;
  color: var(--white);
  transition: color 0.3s;
}

.menuItem-link {
  text-decoration: none;
  color: var(--white);
}

.menuItem {
  transition: background-color 0.3s, box-shadow 0.3s;
}
p {
  display: flex;
  justify-content: flex-start;
}

.menuItem-content:hover,
.menuItem.active {
  background-color: var(--orange);
  border-radius: 10px;
  color: var(--cream);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.menuItem-content:hover .menuItem-icon {
  color: var(--cream); /* Change icon color on hover */
}

.menuItem:focus-within {
  outline: none;
  box-shadow: 0 0 0 3px rgba(55, 56, 55, 0.5);
}
.submenu {
  border-radius: 5px;
  margin-left: 25px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  transition: max-height 0.3s ease-in-out;
  transition: background-color 0.3s, box-shadow 0.3s;
}
.menuItem:hover .submenu {
  display: flex; /* This will show the submenu on hover */
}
.submenuItem-icon {
  margin-right: 15px;
  font-size: 20px;
  min-width: 30px;
  color: var(--white);
  transition: color 0.3s;
}

.submenu-item {
  text-decoration: none;
  color: var(--white);
  font-size: 12px;
  border-radius: 10px;
  margin-top: 0.2rem;
  margin-bottom: 0.1rem;
}

.submenu-item:hover .menuItem-icon,
.submenu-item.active {
  color: var(--cream);
}

.submenu-item:hover,
.submenu-item.active {
  background-color: var(--lightglass);
  color: var(--cream);
  border-radius: 10px;
}
.submenu_item_name {
  font-size: 16px;
}