.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-section {
  background-color: #fff;
}

.login-card {
  width: 400px;
  border-radius: 15px;
  background: var(--sidebar-menubg);
  padding: 7rem 2rem;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-logo {
  width: 150px;
  height: auto;
  margin: 0 auto 20px;
}

.login-input {
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid #ddd;
  transition: border 0.3s;
}

.login-input:focus {
  border: 1px solid #ff9900;
  box-shadow: 0px 0px 5px rgba(255, 153, 0, 0.5);
}

.login-eye-icon {
  position: absolute;
  right: 15px;
  top: 5px;
  cursor: pointer;
  color: #888;
}

.login-btn {
  width: 100%;
  padding: 12px 0;
  background-color: var(--white);
  color: black;
  border: none;
  border-radius: 25px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.login-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.image-container {
  text-align: center;
}

.login-image {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .login-card {
    width: 90%;
    padding: 20px;
  }

  .login-btn {
    width: 100%;
  }

  .login-image {
    width: 80%;
  }
}
