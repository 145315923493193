/* Organization.css */

/* Existing styles remain the same */

/* .edit-button,
.delete-button {
  border: none !important;
  padding: 10px 15px !important;
  margin-right: 5px !important;
  font-size: 16px !important;
  cursor: pointer !important;
  transition: background 0.3s ease, transform 0.3s ease !important;
  position: relative !important;
  color: var(--cream) !important;
} */

/* .edit-button {
  background-color: #8bc34a !important; 
}

.edit-button:hover {
  background-color: #7cb342 !important; 
  transform: scale(1.05); 
}

.delete-button {
  background-color: #f56335 !important ; 
}

.delete-button:hover {
  background-color: #e53935 !important;
  transform: scale(1.05) !important;
} */

/* Table Styling */
.organization-table {
  margin-top: 20px;
}

.organization-table table {
  width: 100%;
  border-collapse: collapse;
}

.organization-table th,
.organization-table td {
  padding: 15px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #ddd;
}

.organization-table th {
  /* background-color: var(--orange) !important; */
  padding: 15px !important;
  border: 1px solid #ddd !important;
}

.organization-table tbody tr:hover {
  background-color: #f1f1f1 !important;
  text-transform: capitalize !important;
}

.organization-table tbody td {
  font-size: 16px;
  color: #6c757d;
  text-transform: capitalize !important;
}

.process-form-container {
  background: var(--sidebar-bg);
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-family: "Inter", sans-serif;
}

.process-form-container:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

/* .form-control { 
  background-color: red !important;
  border-radius: 34px !important;
  border: 1px solid var(--textbox-outline) !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */

/* Add box shadow on focus */

.form-actions {
  margin-top: 20px !important;
  padding-top: 20px !important;
  display: flex;
  justify-content: space-evenly;
}

/* Button styles */
.update-btn {
  padding: 10px 30px !important;
  transition: background-color 0.3s ease;
  color: var(--white) !important;
  margin-right: 10px;
  border: none !important;
  outline: none !important;
  background: linear-gradient(45deg, #32cd32, #228b22) !important;
  transition: box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out !important;
  box-shadow: inset 0 4px 6px rgba(173, 255, 47, 0.5),
    0 6px 12px rgba(0, 0, 0, 0.2) !important;
}

.cancel-btn {
  padding: 10px 30px !important;
  outline: none !important;
  border: none !important;
  transition: background-color 0.3s ease;
  color: var(--white) !important;
  background: linear-gradient(45deg, #ffcc00, #ff9933);
  transition: box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out !important;
  box-shadow: inset 0 4px 6px rgba(255, 224, 47, 0.5),
    0 6px 12px rgba(0, 0, 0, 0.2) !important;
}

.update-btn:hover,
.cancel-btn:hover {
  box-shadow: inset 0 4px 6px rgba(173, 255, 47, 0.6),
    0 8px 16px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

.form-control::placeholder {
  color: var(--placeholder);
  opacity: 1;
}

/* .form-control:focus::placeholder {
  color: var(--placeholder);
} */

.custom-input {
  background-color: white !important;
}
