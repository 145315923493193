.ticket {
  /* width: 15rem; */
  border-radius: 15px;
  transition: box-shadow 0.3s ease;
  margin-bottom: 10px;
}

.count_disc {
  height: 45px;
  width: 45px;
  font-weight: bold;
  font-size: 25px;
}

.ticket_icon {
  font-size: 2.5rem;
}

.ticket_info {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  width: 100%;
}

.ticket:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.issubtaskrow {
  background-color: #b1d6f4 !important;
}