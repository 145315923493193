.chartContainer {
  text-transform: capitalize;
  /* background-color: #f9f2e9; */
  padding: 16px 0px;
  /* height: 70vh;
    overflow-y: scroll; */
}
.titleContainer {
  padding: 0px 24px 20px 16px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notesContainer {
  font-size: 12px;
  text-transform: capitalize !important;
  margin-right: 10px !important;
  font-weight: 600;
  background-color: #fab005 !important;
  color: #ffffff !important;
}
.module {
  /* background-color: #087f5b; */
  color: #fab005;
  padding: 8px 16px;
  border-radius: 3px;
  text-align: center;
  border-bottom: 3px solid #ffe066;
  font-size: 24px;
}
.headerButton {
  border: none;
  background: none;
  outline: none;
}
.headerButton:hover {
  border-bottom: 1px solid #fab005;
}
.menuContainer {
  border-left: 5px dashed #ffd43b;
  margin: 10px 0px;
  /* padding: 24px 0px; */
  padding: 40px 16px 20px 16px;
  margin-left: 15px;
  /* margin-bottom: 30px; */
  position: relative;
}
.menuPosition {
  position: absolute;
  top: -20px;
  left: -24px;
}
.clearIcon {
  color: #fff;
}
.menu {
  background: linear-gradient(45deg, rgb(255, 152, 0), #ffc107);
  color: #fff;
  padding: 10px 15px;
  border-radius: 8px;
  margin-left: 15px;
  border: none;
  border-left: 6px solid #e67700;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menuClear {
  /* background: none; */
  outline: none;
  background-color: #ff6b6b;
  padding: 0px 5px;
  /* border: none; */
  border: 1px solid #c92a2a;
  border-radius: 3px;
  margin-left: 10px;
}
.editIcon {
  /* background: none; */
  outline: none;
  background-color: #ff6b6b;
  padding: 0px 5px;
  /* border: none; */
  border: 1px solid #c92a2a;
  border-radius: 20px;
  margin-left: 10px;
}
.menuClear:hover {
  transform: scale(1.2);
}
.menu:hover {
  background-color: #f59f00;
}
.menuColor {
  color: #fab005;
}
.subMenu {
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 15px;
  color: #000000;
  padding: 10px 15px;
  border-radius: 10px;
  margin: 5px;
  border: 1px solid grey;
  border-left: 5px solid #f76707;
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  border: none;
}
.subMenuColor {
  color: #f76707;
}
.functionColor {
  color: #1971c2;
}
.subMenuContainer {
  display: flex;
  flex-wrap: wrap;
  margin-left: 30px;
}
.functionItem {
  background-color: #74c0fc;
  color: #fff;
  padding: 8px 16px;
  border-radius: 10px;
  margin: 5px;
  border: none;
  border-left: 5px solid #1864ab;
}
.submenuFunctionListContainer {
  border: 3px dashed #1971c2;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 8px;
}

.editStaff {
  border: none !important;
  background-color: transparent !important;
}
.form-control {
  border-radius: 5px !important;
  border: 1px solid var(--textbox-outline) !important;
}
