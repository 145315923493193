/* 
--
user and notification section
--
*/
.nav-left {
  width: 230px !important; /* Adjust based on your design */
}

.nav-middle {
  flex-grow: 1!important;  /* Allows this section to expand */
}

.nav-end {
  width: 230px !important;  /* Adjust based on your design */
}
.bell {
  position: relative;
  color: var(--orange);
  margin-right: 2rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.bell:hover {
  transform: scale(1.2);
}

.bell-badge {
  position: absolute;
  top: -5px;
  right: -10px;
  font-size: 0.75rem;
}

.user {
  transition: color 0.3s ease;
}

.user:hover {
  color: var(--orange);
}

.name {
  font-size: 1rem;
}

.role {
  font-size: 0.875rem;
  color: #6c757d;
}

/* 
--
dept section
--
*/

